import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Lottie from 'react-lottie';
import loadingAnimationData from '../animations/loading.json';

function SubmissionSuccess() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isDemo = searchParams.get('demo') === 'true';
  const message = location.state?.message || 'Success!';
  let bookingId = localStorage.getItem('bookingId');

  const [showLoadingScreen, setShowLoadingScreen] = useState(false);

  const handleContinueClick = () => {
    setShowLoadingScreen(true);
    setTimeout(() => {
      window.location.href = `/?bookingId=${bookingId}&demo=true`;
    }, 5000);
  };

  if (showLoadingScreen) {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: loadingAnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <div className="loading-error-screen">
        <Lottie options={defaultOptions} height={200} width={300} />
        <p>Placing the final touches on your new welcome book...</p>
      </div>
    );
  }

  return (
    <div className="survey-success">
      <p>{message}</p>
      {isDemo && (
        <div>
          <p>👆This is all the guest will see after completing the survey.</p>
          <ul>
            <li>Each guidebook is unique and personalized for every guest, all automated and powered by AI behind the scenes.</li>
            <li>Guests receive an email a day or so after completing the survey, giving the impression of a meticulously crafted welcome letter and recommendations from you.</li>
            <li>No additional work is needed after the initial setup of your property.</li>
            <li>For any additional questions, use the chat icon at the top-right. In this demo, you can even ask questions about how Welcome Compass works!</li>
          </ul>
          <button onClick={handleContinueClick}>Show Me the Guidebook</button>
        </div>
      )}
    </div>
  );
}

export default SubmissionSuccess;