import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { iconMapping } from '../shared/icons';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import useNavigateWithTracking from '../shared/navigateWithTracking';
import Helmet from 'react-helmet';
import { trackEvent } from '../shared/trackingManager';

function HouseInfoDetail({ houseManualItems }) {
  const navigateWithTracking = useNavigateWithTracking();
  const { itemId } = useParams();
  const item = houseManualItems.find(item => item.id === itemId);

  if (!item) {
    return <div>Item not found</div>; // Handle item not found case
  }

  trackEvent(`House Info : ${item.title}`, { type: item.type })

  return (
    <div className="house-info-detail">
      <Helmet>
        <title> | {item.title}</title>
      </Helmet>
      <div className="house-info-detail-header">
        <button className="house-info-detail-back-button" onClick={() => navigateWithTracking(-1)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <div>
          <FontAwesomeIcon icon={iconMapping[item.type]} size="2x" />
          <h1>{item.title}</h1>
        </div>
      </div>
      <div className="house-info-detail-content">
          <Markdown rehypePlugins={[rehypeRaw]}>{item.content}</Markdown>
      </div>
    </div>
  );
}

HouseInfoDetail.propTypes = {
  houseManualItems: PropTypes.array.isRequired
};

export default HouseInfoDetail;