import { useNavigate, useLocation } from 'react-router-dom';
import { trackEvent } from './trackingManager';

/**
 * Custom hook to navigate with tracking.
 */
const useNavigateWithTracking = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Optional, if you want to track the current page.

  /**
   * Tracks the navigation event and then navigates to the new route.
   * @param {string} to - The path to navigate to.
   * @param {object} [options] - Navigation options for `navigate` function.
   */
  const navigateWithTracking = (to, options = {}) => {
    trackEvent('Button Click', { from: location.pathname, to });
    navigate(to, options);
  };

  return navigateWithTracking;
};

export default useNavigateWithTracking;