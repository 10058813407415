import {
  faKey,
  faListCheck,
  faJugDetergent,
  faBath,
  faUtensils,
  faLightbulb,
  faPersonSwimming,
  faHotTubPerson,
  faBurger,
  faFire,
  faHouseChimney,
  faTrashCan,
  faVolumeHigh,
  faPuzzlePiece,
  faWifi,
  faPhoneVolume,
  faToilet,
  faDumbbell,
  faInfoCircle,
  faTv,
  faTemperatureHalf,
  faUserTie,
  faMartiniGlass,
  faPeopleGroup,
  faChair,
  faHandHoldingHeart,
} from '@fortawesome/free-solid-svg-icons';

export const iconMapping = {
  checkin: faKey,
  checkout: faListCheck,
  laundry: faJugDetergent,
  linens: faBath,
  kitchen: faUtensils,
  lightsFans: faLightbulb,
  pool: faPersonSwimming,
  hotTub: faHotTubPerson,
  grill: faBurger,
  firepit: faFire,
  fireplace: faHouseChimney,
  garbage: faTrashCan,
  music: faVolumeHigh,
  gameRoom: faPuzzlePiece,
  wifi: faWifi,
  contactInfo: faPhoneVolume,
  bathroom: faToilet,
  gym: faDumbbell,
  generalInfo: faInfoCircle,
  remotes: faTv,
  ac_heat: faTemperatureHalf,
  dressCode: faUserTie,
  drinks: faMartiniGlass,
  weddingTeam: faPeopleGroup,
  seatingAssignment: faChair,
  weddingDetails: faHandHoldingHeart
};
