import React, { useState } from 'react';
import config from '../config';
import logoImg from '../images/logo.png';
import { useLocation } from 'react-router-dom';

function LoadingOverlay() {
  return (
    <div className="loading-overlay">
      <div className="loader">Sending...</div>
    </div>
  );
}

const Demo = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const propertyId = queryParams.get('propertyId')
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const calculateDates = () => {
    const today = new Date();
    const arrivalDate = new Date(today.setDate(today.getDate() + 3)).toISOString().split('T')[0];
    const departureDate = new Date(today.setDate(today.getDate() + 3)).toISOString().split('T')[0];
    return { arrivalDate, departureDate };
  };

  const { arrivalDate, departureDate } = calculateDates();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const data = {
      arrivalDate: arrivalDate,
      departureDate: departureDate,
      propertyId: propertyId || 'TbEf3PiqB41ZVyA0o2dM',
      guestFirstName: firstName,
      guestLastName: lastName,
      isDemo: true,
    };

    try {
      const response = await fetch(`${config.API_BASE_URL}/createBooking`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      const bookingId = result.bookingId;
      
      // Trigger page reload so that the new bookingId is picked up by the app
      window.location.href = `/survey?bookingId=${bookingId}&demo=true`;
    } catch (error) {
      console.error('Error creating booking:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="demo">
      {isSubmitting && <LoadingOverlay />}
      <img className="logo" src={logoImg} alt="Logo" />
      <p>Experience firsthand how our product enhances the guest’s stay from the moment they book your fantastic rental property.</p>
      <p>Let’s build a sample reservation</p>
      <p><strong>Here’s what to do:</strong></p>
      <p>1. <strong>Enter a name for the reservation:</strong> This can be yours, or make one up!</p>
      <p>2. <strong>Continue to the survey:</strong> Next, you’ll experience the same 30 second survey your guests will complete before their stay.</p>
      <p>Ready to experience the magic as your guests will? Let’s go!</p>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            First Name:&nbsp;&nbsp;
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Last Name:&nbsp;&nbsp;
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </label>
        </div>
        <button type="submit">Continue To Survey</button>
      </form>
    </div>
  );
};

export default Demo;