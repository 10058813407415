import React, { useState, useEffect, useRef } from 'react';
import config from '../config';
import Markdown from 'react-markdown';
import { trackEvent, trackErrorEvent } from '../shared/trackingManager';
import Helmet from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

function AskQuestion() {
  const [messages, setMessages] = useState([
    { id: 1, text: "Welcome to instant chat! I have all the information about the property and you can ask me anything! Try things like “Where can I find more towels?” or “When is check-out?”\n\nRemember to always verify opening hours and locations, and if you need additional help, reach out to your host!", type: 'received' },
  ]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  
  useEffect(() => {
    scrollToBottom(); // Scrolls to bottom whenever messages change
  }, [messages]);
  
  const sendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      const userMessage = { id: messages.length + 1, text: newMessage, type: 'sent' };
      setMessages(msgs => [...msgs, userMessage]);
      setIsLoading(true);
      trackEvent('Ask : Question', { question: newMessage });
      
      try {
        const res = await fetch(`${config.API_BASE_URL}/ask`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            bookingId: localStorage.getItem('bookingId'),
            query: newMessage,
            context: messages.map(message => ({ text: message.text, type: message.type }))
          })
        });
        const data = await res.json();
        const apiMessage = { id: messages.length + 2, text: data.chatGPTResponse, type: 'received' };
        setMessages(msgs => [...msgs, apiMessage]);
        
        // Track the event response
        trackEvent('Ask : Response', { response: data.chatGPTResponse });
      } catch (error) {
        console.error('Error:', error);
        trackErrorEvent(error);
        setMessages(msgs => [...msgs, { id: messages.length + 2, text: 'Failed to fetch response.', type: 'received' }]);
      }
      
      setIsLoading(false); // Hide loading indicator
      setNewMessage('');
    }
  };
  
  return (
    <div className="chat-container">
      <Helmet>
        <title> | Ask Me Anything</title>
      </Helmet>
    <div className="chat-messages">
      {messages.map((message) => (
        <div key={message.id} className={`message ${message.type}`}>
          <Markdown>{message.text}</Markdown>
        </div>
      ))}
      {isLoading && <div className="message typing-indicator">Typing...</div>}
      <div ref={messagesEndRef} /></div>
      <form className="chat-input" onSubmit={sendMessage}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Ask me anything!"
        />
        <button disabled={isLoading} type="submit"><FontAwesomeIcon icon={faPaperPlane} /></button>
      </form>
    </div>
  );
}

export default AskQuestion;
